import { template as template_87d8f6b47c6542588529ab289a0691ae } from "@ember/template-compiler";
const EmptyState = template_87d8f6b47c6542588529ab289a0691ae(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
