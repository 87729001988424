import { template as template_e26936a03cb54ad7be19ce5f53c70b82 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_e26936a03cb54ad7be19ce5f53c70b82(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
